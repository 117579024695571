import React, { FC } from "react"
import { AllGraphQLResponse, ResourceCategory } from "@utils/globalTypes"
import SEO from "@components/common/SEO"
import CardListContainer from "@components/Categories"
import { graphql } from "gatsby"

type Props = {
  data: {
    allSanityResourceCategory: AllGraphQLResponse<ResourceCategory>
  }
  location: Location
}

const KnowledgePage: FC<Props> = ({
  data: {
    allSanityResourceCategory: { edges },
  },
  location,
}) => {
  const metadata = {
    location: location.href,
  }
  const categories = edges.map((edge) => edge.node)

  return (
    <>
      <SEO {...metadata} />
      {categories?.length > 0 && (
        <CardListContainer
          categories={categories}
          title={"Resources Categories"}
        />
      )}
    </>
  )
}
export default KnowledgePage

export const pageQuery = graphql`
  query {
    allSanityResourceCategory {
      edges {
        node {
          slug {
            current
          }
          name
        }
      }
    }
  }
`
